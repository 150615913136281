import React from 'react';
import Percentage from 'views/common/components/UI/Icons/rewards/Percentage';
import Lock from 'views/common/components/UI/Icons/rewards/Lock';
import Rocket from 'views/common/components/UI/Icons/rewards/Rocket';
import Star from 'views/common/components/UI/Icons/rewards/Star';
import Dollar from 'views/common/components/UI/Icons/rewards/Dollar';
import Gift from 'views/common/components/UI/Icons/rewards/Gift';
import Bike from 'views/common/components/UI/Icons/rewards/Bike';
import Users from 'views/common/components/UI/Icons/rewards/Users';

import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';

const useRewardBenefitsList = () => {
  const { t } = useTranslation('fe_er_common_rewards_items_list');
  const rewardData = useSelector((state) => state.globalData.appState)?.reward;

  if (!rewardData) return [];

  const formattedDiscount = Number.isInteger(rewardData.deal.discount_percent)
    ? parseInt(rewardData.deal.discount_percent, 10)
    : rewardData.deal.discount_percent;

  const rewardBenefitsList = [
    {
      icon: (props) => <Percentage {...props} />,
      text: t('fe_er_common_rewards_items_list:percentage', {
        discount_percent: formattedDiscount,
      }),
      desc: t('fe_er_common_rewards_items_list:discounts_desc', {
        discount_percent: formattedDiscount,
      }),
    },

    {
      icon: (props) => <Dollar {...props} />,
      text: t(
        `fe_er_common_rewards_items_list:${
          rewardData.points_per_unit > 1 ? 'earn_points' : 'earn_point'
        }`,
        {
          points_per_unit: rewardData.points_per_unit,
          point_value: '$1',
        },
      ),
      desc: t('fe_er_common_rewards_items_list:earn_desc', {
        points_target: rewardData.points_target.toLocaleString('en-US'),
        points_target_amount: moneyFormat(
          Dinero({ ...rewardData.points_target_amount }),
        ),
        points_per_unit: moneyFormat(Dinero({ ...rewardData.points_per_unit })),
      }),
    },
    {
      icon: (props) => <Lock {...props} />,
      text: t('fe_er_common_rewards_items_list:exclusive_deals'),
      desc: t('fe_er_common_rewards_items_list:exclusive_desc'),
    },
    {
      icon: (props) => <Rocket {...props} />,
      text: t('fe_er_common_rewards_items_list:faster_checkIn'),
      desc: t('fe_er_common_rewards_items_list:check_in_desc'),
    },
    {
      icon: (props) => <Gift {...props} />,
      text: t('fe_er_common_rewards_items_list:birthday_reward', {
        birthday_reward: moneyFormat(Dinero({ ...rewardData.birthday_reward })),
      }),
      desc: t('fe_er_common_rewards_items_list:birthday_reward_desc', {
        birthday_reward: moneyFormat(Dinero({ ...rewardData.birthday_reward })),
      }),
    },
    {
      icon: (props) => <Bike {...props} />,
      text: t('fe_er_common_rewards_items_list:early_fleet_access'),
      desc: t('fe_er_common_rewards_items_list:early_access_desc'),
    },
    {
      icon: (props) => <Users {...props} />,
      text: t('fe_er_common_rewards_items_list:referral_reward', {
        referral_reward: moneyFormat(Dinero({ ...rewardData.referral_reward })),
      }),
      desc: t('fe_er_common_rewards_items_list:referral_reward_desc', {
        referral_reward: moneyFormat(Dinero({ ...rewardData.referral_reward })),
      }),
    },
    {
      icon: (props) => <Star {...props} />,
      text: t('fe_er_common_rewards_items_list:club_upgrades'),
      desc: t('fe_er_common_rewards_items_list:club_upgrades_desc', {
        enrollment_fee: '$29',
        points_target: rewardData.points_target.toLocaleString('en-US'),
      }),
    },
  ];
  return { rewardBenefitsList, formattedDiscount };
};

export default useRewardBenefitsList;
