import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Rocket = (props) => (
  <SvgIcon
    {...props}
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17840_365607)">
      <path
        d="M37.4445 57.6393C37.2327 57.6385 37.0232 57.5954 36.8283 57.5125C36.6333 57.4295 36.457 57.3085 36.3095 57.1565C36.1619 57.0045 36.0463 56.8246 35.9693 56.6273C35.8922 56.43 35.8554 56.2193 35.8609 56.0076L36.1845 45.8658C36.197 45.4912 36.3408 45.1329 36.5907 44.8537L48.2954 31.9509C48.4456 31.797 48.6258 31.6755 48.8247 31.5938C49.0236 31.5121 49.2371 31.472 49.4521 31.4758C49.6656 31.4755 49.8767 31.5201 50.0718 31.6067C50.3749 31.7341 50.6303 31.9535 50.8021 32.234C50.9738 32.5144 51.0531 32.8417 51.0288 33.1696L49.879 49.4185C49.8625 49.6676 49.7872 49.9092 49.6591 50.1234C49.531 50.3377 49.3539 50.5185 49.1423 50.6509L38.2776 57.4121C38.0276 57.5667 37.7383 57.6456 37.4445 57.6393Z"
        fill="#FF9547"
      />
      <path
        d="M49.4526 33.0266L48.3028 49.31L37.4449 56.0712L37.7685 45.9363L49.4732 33.0335M49.4732 29.8664C49.0311 29.8666 48.5938 29.9591 48.1895 30.1382C47.7852 30.3172 47.4227 30.5787 47.1254 30.906L35.4207 43.8088C34.9183 44.3663 34.6282 45.0829 34.6013 45.833L34.2571 55.9748C34.2499 56.5411 34.3945 57.099 34.6756 57.5906C34.9568 58.0822 35.3644 58.4897 35.8561 58.7707C36.3478 59.0516 36.9058 59.1959 37.4721 59.1886C38.0384 59.1812 38.5924 59.0225 39.0767 58.7288L49.9758 51.9952C50.4014 51.731 50.7585 51.37 51.0181 50.9417C51.2777 50.5133 51.4325 50.0298 51.4699 49.5303L52.6197 33.2538C52.6689 32.5926 52.5094 31.9325 52.1635 31.3668C51.8176 30.801 51.3029 30.3581 50.6919 30.1005C50.3005 29.9318 49.8787 29.8451 49.4526 29.8457L49.4732 29.8664Z"
        fill="#303030"
      />
      <path
        d="M13.3191 33.5417C13.0363 33.5418 12.7587 33.4661 12.5151 33.3225C12.2715 33.179 12.0707 32.9729 11.9337 32.7255C11.7967 32.4782 11.7284 32.1987 11.7359 31.916C11.7434 31.6334 11.8265 31.3579 11.9765 31.1182L18.7377 20.2534C18.8713 20.0408 19.0531 19.8626 19.2684 19.7334C19.4838 19.6042 19.7265 19.5276 19.977 19.5098L36.2535 18.3944H36.3705C36.6894 18.3957 37.0005 18.4932 37.2631 18.6743C37.5257 18.8553 37.7274 19.1114 37.842 19.409C37.9566 19.7067 37.9787 20.032 37.9053 20.3423C37.8319 20.6527 37.6666 20.9337 37.4308 21.1485L24.5281 32.8532C24.2508 33.1063 23.8913 33.2506 23.516 33.2594L13.3191 33.5417Z"
        fill="#FFC396"
      />
      <path
        d="M36.3708 19.9438L23.4681 31.6485L13.3194 31.9721L20.0875 21.1142L36.3708 19.9644M36.3708 16.7973H36.1436L19.8603 17.9333C19.3607 17.9672 18.8765 18.1198 18.4478 18.3785C18.0191 18.6371 17.6583 18.9944 17.3954 19.4205L10.6342 30.2784C10.3413 30.7572 10.181 31.3053 10.1696 31.8666C10.1582 32.4278 10.2962 32.9819 10.5694 33.4723C10.8426 33.9627 11.2412 34.3716 11.7244 34.6572C12.2076 34.9429 12.7581 35.095 13.3194 35.0979H13.4227L23.5645 34.7675C24.3144 34.7461 25.0321 34.458 25.5887 33.955L38.4915 22.2503C38.9629 21.8207 39.2937 21.2587 39.4404 20.638C39.5872 20.0172 39.5431 19.3666 39.3139 18.7714C39.0847 18.1761 38.6811 17.6639 38.156 17.3019C37.6309 16.9398 37.0087 16.7447 36.3708 16.7422V16.7973Z"
        fill="#303030"
      />
      <path
        d="M35.9305 46.2457C35.7218 46.2454 35.5152 46.2039 35.3226 46.1236C35.13 46.0432 34.9552 45.9256 34.8082 45.7775L23.5304 34.4996C23.2356 34.2018 23.0703 33.7998 23.0703 33.3808C23.0703 32.9618 23.2356 32.5598 23.5304 32.262L43.4973 12.2951C46.2513 9.54103 51.2775 7.59943 55.6977 7.59943C57.1366 7.57521 58.5683 7.80838 59.9252 8.28794C60.1572 8.35784 60.371 8.4777 60.5517 8.63908C60.7748 8.84221 60.9349 9.10506 61.013 9.39645C62.9822 14.7531 60.5035 22.3061 57.0128 25.7968L37.0459 45.7637C36.9009 45.9135 36.7277 46.0332 36.5363 46.1159C36.3449 46.1986 36.139 46.2427 35.9305 46.2457Z"
        fill="#FFC396"
      />
      <path
        d="M55.7181 9.1684C57.0043 9.15046 58.283 9.36745 59.4912 9.80872C61.2951 14.4975 59.0161 21.5548 55.8972 24.6737L35.9303 44.6406L24.6524 33.3765L44.6193 13.4096C46.974 11.0618 51.5733 9.18217 55.7044 9.18217M55.7044 6.01501C50.8848 6.01501 45.418 8.13563 42.3816 11.172L22.4148 31.1389C21.8232 31.7347 21.4912 32.5403 21.4912 33.38C21.4912 34.2196 21.8232 35.0252 22.4148 35.6211L33.6926 46.8989C33.9862 47.1933 34.3349 47.4268 34.7189 47.5862C35.1029 47.7455 35.5145 47.8276 35.9303 47.8276C36.346 47.8276 36.7577 47.7455 37.1416 47.5862C37.5256 47.4268 37.8744 47.1933 38.1679 46.8989L58.1348 26.932C62.0456 23.0144 64.7101 14.8624 62.5 8.85857C62.3776 8.4871 62.1912 8.13991 61.9492 7.83269C61.556 7.33412 61.0242 6.96285 60.4207 6.76549C58.9098 6.23835 57.3182 5.9797 55.7181 6.00124L55.7044 6.01501Z"
        fill="#303030"
      />
      <path
        d="M59.5045 9.83008C61.3015 14.5189 59.0226 21.5348 55.9105 24.6813L35.9436 44.6482L30.3047 39.0506L59.5045 9.83008Z"
        fill="#FF9547"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.6521 14.344C49.2386 14.1001 49.8675 13.9746 50.5026 13.9746C51.1378 13.9746 51.7667 14.1001 52.3532 14.344C52.9396 14.5879 53.4721 14.9452 53.9201 15.3956L52.2893 17.0175L53.9156 15.3911C54.5907 16.0662 55.0504 16.9262 55.2367 17.8625C55.423 18.7989 55.3274 19.7694 54.9621 20.6514C54.5968 21.5334 53.9781 22.2873 53.1843 22.8177C52.3905 23.3481 51.4573 23.6312 50.5026 23.6312C49.548 23.6312 48.6147 23.3481 47.8209 22.8177C47.0272 22.2873 46.4085 21.5334 46.0432 20.6514C45.6778 19.7694 45.5823 18.7989 45.7686 17.8625C45.9546 16.9272 46.4136 16.068 47.0874 15.3933C47.5349 14.9441 48.0666 14.5875 48.6521 14.344ZM48.7159 17.0175L50.3422 18.6439C50.3105 18.6756 50.2889 18.7161 50.2801 18.7601C50.2714 18.8041 50.2759 18.8497 50.293 18.8911C50.3102 18.9326 50.3393 18.968 50.3766 18.9929C50.4139 19.0179 50.4578 19.0312 50.5026 19.0312C50.5475 19.0312 50.5913 19.0179 50.6287 18.9929C50.666 18.968 50.695 18.9326 50.7122 18.8911C50.7294 18.8497 50.7339 18.8041 50.7251 18.7601C50.7164 18.7161 50.6947 18.6756 50.663 18.6439L50.6586 18.6395L50.6586 18.6395C50.6381 18.6189 50.6138 18.6026 50.5871 18.5915C50.5603 18.5803 50.5316 18.5746 50.5026 18.5746C50.4736 18.5746 50.4449 18.5803 50.4182 18.5915C50.3914 18.6026 50.3671 18.6189 50.3467 18.6395L48.7159 17.0175Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0304 43.3572C26.9286 44.2555 26.9286 45.7117 26.0304 46.6099L12.5631 60.0773C11.6649 60.9755 10.2086 60.9755 9.31037 60.0773C8.41217 59.1791 8.41217 57.7228 9.31037 56.8246L22.7777 43.3572C23.6759 42.459 25.1322 42.459 26.0304 43.3572Z"
        fill="#5D5D5D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9855 37.3133C20.8837 38.2115 20.8837 39.6678 19.9855 40.566L9.92635 50.6252C9.02814 51.5234 7.57186 51.5234 6.67365 50.6252C5.77545 49.727 5.77545 48.2707 6.67365 47.3725L16.7328 37.3133C17.631 36.4151 19.0873 36.4151 19.9855 37.3133Z"
        fill="#5D5D5D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9933 49.3204C32.8915 50.2186 32.8915 51.6749 31.9933 52.5731L21.9342 62.6323C21.036 63.5305 19.5797 63.5305 18.6815 62.6323C17.7833 61.734 17.7833 60.2778 18.6815 59.3796L28.7406 49.3204C29.6388 48.4222 31.0951 48.4222 31.9933 49.3204Z"
        fill="#5D5D5D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5816 22.6414C42.4577 23.5613 42.4222 25.0171 41.5023 25.8932L34.2729 32.7783C33.3531 33.6543 31.8972 33.6188 31.0212 32.699C30.1452 31.7791 30.1807 30.3233 31.1005 29.4473L38.3299 22.5621C39.2497 21.6861 40.7056 21.7216 41.5816 22.6414Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_17840_365607">
        <rect width="69" height="69" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Rocket;
