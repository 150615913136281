import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Gift = (props) => (
  <SvgIcon
    {...props}
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17840_365579)">
      <path
        d="M54.5722 24.5773H13.6289V61.6093H54.5722V24.5773Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8662 24.5773C11.8662 23.6037 12.6555 22.8145 13.6291 22.8145H54.5723C55.5459 22.8145 56.3352 23.6037 56.3352 24.5773V61.6093C56.3352 62.5829 55.5459 63.3722 54.5723 63.3722H13.6291C12.6555 63.3722 11.8662 62.5829 11.8662 61.6093V24.5773ZM15.3919 26.3402V59.8465H52.8095V26.3402H15.3919Z"
        fill="#303030"
      />
      <path d="M58.262 18.4053H9.7627V27.2224H58.262V18.4053Z" fill="#FFC396" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 18.4045C8 17.4309 8.78926 16.6416 9.76285 16.6416H58.2622C59.2358 16.6416 60.025 17.4309 60.025 18.4045V27.2216C60.025 28.1952 59.2358 28.9845 58.2622 28.9845H9.76285C8.78926 28.9845 8 28.1952 8 27.2216V18.4045ZM11.5257 20.1673V25.4588H56.4993V20.1673H11.5257Z"
        fill="#303030"
      />
      <path
        d="M45.6377 8.17679C43.5155 8.96356 39.8524 12.1784 35.4053 16.37C41.5555 16.5599 46.4352 16.5734 48.5304 15.7867C49.0625 15.6274 49.5563 15.3601 49.9813 15.0014C50.4062 14.6427 50.7532 14.2002 51.0008 13.7014C51.2484 13.2026 51.3912 12.6581 51.4205 12.1016C51.4498 11.5452 51.3648 10.9886 51.171 10.4664C50.9771 9.94421 50.6785 9.46754 50.2935 9.06594C49.9086 8.66433 49.4455 8.34634 48.9331 8.13168C48.4207 7.91702 47.8698 7.81026 47.3145 7.81802C46.7593 7.82578 46.2116 7.9479 45.7053 8.17679H45.6377Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2891 6.05525C48.0866 6.04411 48.8778 6.19746 49.6135 6.50564C50.3491 6.81381 51.0134 7.27014 51.5654 7.84599C52.1174 8.42184 52.5452 9.10494 52.8229 9.85279C53.1005 10.6006 53.2221 11.3975 53.1802 12.1942C53.1382 12.9908 52.9337 13.7705 52.5791 14.4851C52.2244 15.1996 51.7271 15.8339 51.1176 16.3484C50.5234 16.8499 49.8351 17.2271 49.093 17.4577C47.7418 17.9498 45.752 18.1366 43.5435 18.2065C41.2335 18.2796 38.4285 18.2269 35.3501 18.1319C34.6379 18.1099 34.0089 17.6613 33.7562 16.995C33.5035 16.3287 33.6768 15.5758 34.1954 15.087C36.4242 12.9863 38.488 11.1002 40.274 9.634C42.0065 8.21162 43.6559 7.03107 45.0242 6.52379C45.0959 6.49719 45.1692 6.47534 45.2435 6.45832C45.8948 6.20192 46.5877 6.06506 47.2891 6.05525ZM48.2512 9.75755C47.962 9.6364 47.6514 9.57624 47.3384 9.58061C47.0255 9.58499 46.7166 9.65381 46.4308 9.78301C46.3165 9.83468 46.1974 9.87385 46.0755 9.90009C45.3103 10.2331 44.1245 11.0345 42.5112 12.359C41.6781 13.0429 40.7712 13.8319 39.7995 14.707C41.1257 14.7222 42.3429 14.717 43.432 14.6825C45.6937 14.6109 47.1667 14.4153 47.9099 14.1363C47.9475 14.1221 47.9855 14.1093 48.024 14.0978C48.3241 14.0079 48.6031 13.8571 48.8434 13.6542C49.0838 13.4513 49.2805 13.2007 49.421 12.9176C49.5615 12.6345 49.6427 12.3252 49.6593 12.0089C49.676 11.6926 49.6277 11.3764 49.5176 11.0798C49.4075 10.7833 49.2381 10.5131 49.0201 10.2857C48.8022 10.0583 48.5404 9.8787 48.2512 9.75755Z"
        fill="#303030"
      />
      <path
        d="M34.4588 20.8469H12.1826V24.7942H34.4588V20.8469Z"
        fill="#FF9547"
      />
      <path
        d="M34.4591 29.6631H16.0488V59.1802H34.4591V29.6631Z"
        fill="#FF9547"
      />
      <path
        d="M23.1447 8.1771C25.2398 8.96386 28.9165 12.1787 33.4447 16.316C27.2944 16.5059 22.4147 16.5195 20.3196 15.7327C19.7875 15.5734 19.2937 15.3061 18.8687 14.9474C18.4437 14.5887 18.0967 14.1463 17.8492 13.6475C17.6016 13.1487 17.4587 12.6042 17.4295 12.0477C17.4002 11.4912 17.4851 10.9346 17.679 10.4124C17.8728 9.89026 18.1715 9.41359 18.5564 9.01198C18.9414 8.61037 19.4044 8.29238 19.9169 8.07772C20.4293 7.86306 20.9802 7.75631 21.5354 7.76407C22.0907 7.77183 22.6384 7.89394 23.1447 8.12284V8.1771Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5109 9.52593C21.198 9.52155 20.8873 9.58172 20.5981 9.70286C20.3089 9.82401 20.0472 10.0037 19.8292 10.231C19.6113 10.4584 19.4418 10.7286 19.3318 11.0251C19.2217 11.3217 19.1734 11.6379 19.19 11.9542C19.2067 12.2706 19.2879 12.5798 19.4284 12.8629C19.5689 13.146 19.7656 13.3966 20.0059 13.5995C20.2463 13.8024 20.5252 13.9533 20.8254 14.0431C20.8638 14.0546 20.9019 14.0675 20.9395 14.0816C21.6827 14.3607 23.1556 14.5563 25.4174 14.6278C26.4834 14.6616 27.6722 14.6672 28.9658 14.6533C27.9986 13.8006 27.0977 13.0292 26.2709 12.3576C24.5131 10.9296 23.2716 10.1069 22.5251 9.82661C22.3855 9.7742 22.2555 9.70535 22.137 9.62274C21.9342 9.56159 21.7235 9.5289 21.5109 9.52593ZM19.2359 6.45095C19.9716 6.14277 20.7627 5.98942 21.5602 6.00057C22.3577 6.01171 23.1443 6.18712 23.8711 6.51572C23.9862 6.56776 24.0938 6.63121 24.1928 6.70438C25.4547 7.27886 26.9374 8.35655 28.4939 9.62101C30.2849 11.0759 32.3699 12.9452 34.6339 15.0138C35.1637 15.4978 35.3472 16.2546 35.0979 16.9275C34.8487 17.6004 34.2165 18.0551 33.4992 18.0772C30.4209 18.1723 27.6159 18.2249 25.3059 18.1518C23.0973 18.0819 21.1076 17.8952 19.7563 17.4031C19.0143 17.1724 18.3259 16.7952 17.7318 16.2937C17.1223 15.7792 16.6249 15.1449 16.2703 14.4304C15.9156 13.7158 15.7111 12.9361 15.6692 12.1395C15.6273 11.3428 15.7489 10.5459 16.0265 9.7981C16.3041 9.05024 16.732 8.36715 17.284 7.79131C17.8359 7.21545 18.5002 6.75912 19.2359 6.45095Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3311 61.0929V19.286H35.8568V61.0929H32.3311Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5489 32.7607C46.6547 32.7607 47.5511 33.6572 47.5511 34.763V42.5282C47.5511 42.5464 47.5509 42.5645 47.5504 42.5826L47.3241 50.9224C47.2941 52.0278 46.3737 52.8996 45.2683 52.8696C44.1629 52.8396 43.2911 51.9192 43.3211 50.8138L43.5466 42.5011V34.763C43.5466 33.6572 44.4431 32.7607 45.5489 32.7607Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_17840_365579">
        <rect width="69" height="69" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Gift;
