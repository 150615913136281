import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Percentage = (props) => (
  <SvgIcon
    {...props}
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4463 8.79488C61.319 9.88698 61.1412 11.4798 60.0491 12.3525C39.7552 28.5699 29.095 38.5446 12.3724 60.0239C11.5136 61.1269 9.92321 61.325 8.82013 60.4662C7.71704 59.6074 7.51901 58.017 8.37781 56.9139C25.393 35.0587 36.3533 24.8081 56.8887 8.39768C57.9808 7.52495 59.5736 7.70279 60.4463 8.79488Z"
      fill="#303030"
    />
    <path
      d="M27.25 19.6563C27.25 23.8502 23.8502 27.25 19.6563 27.25C15.4623 27.25 12.0625 23.8502 12.0625 19.6563C12.0625 15.4623 15.4623 12.0625 19.6563 12.0625C23.8502 12.0625 27.25 15.4623 27.25 19.6563Z"
      fill="#FF9547"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3125 19.6563C32.3125 26.6461 26.6461 32.3125 19.6563 32.3125C12.6664 32.3125 7 26.6461 7 19.6563C7 12.6664 12.6664 7 19.6563 7C26.6461 7 32.3125 12.6664 32.3125 19.6563ZM19.6563 27.25C23.8502 27.25 27.25 23.8502 27.25 19.6563C27.25 15.4623 23.8502 12.0625 19.6563 12.0625C15.4623 12.0625 12.0625 15.4623 12.0625 19.6563C12.0625 23.8502 15.4623 27.25 19.6563 27.25Z"
      fill="#303030"
    />
    <path
      d="M53.4063 48.3438C53.4063 52.5377 50.0064 55.9375 45.8125 55.9375C41.6186 55.9375 38.2188 52.5377 38.2188 48.3438C38.2188 44.1499 41.6186 40.75 45.8125 40.75C50.0064 40.75 53.4063 44.1499 53.4063 48.3438Z"
      fill="#FFC396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.4688 48.3438C58.4688 55.3336 52.8024 61 45.8125 61C38.8226 61 33.1562 55.3336 33.1562 48.3438C33.1562 41.3539 38.8226 35.6875 45.8125 35.6875C52.8024 35.6875 58.4688 41.3539 58.4688 48.3438ZM45.8125 55.9375C50.0064 55.9375 53.4063 52.5377 53.4063 48.3438C53.4063 44.1499 50.0064 40.75 45.8125 40.75C41.6186 40.75 38.2188 44.1499 38.2188 48.3438C38.2188 52.5377 41.6186 55.9375 45.8125 55.9375Z"
      fill="#303030"
    />
  </SvgIcon>
);

export default Percentage;
