import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Lock = (props) => (
  <SvgIcon
    {...props}
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5605 28.792V44.0321C13.5605 49.5974 15.7714 54.9348 19.7067 58.8702C23.642 62.8055 28.9794 65.0163 34.5448 65.0163C40.1102 65.0163 45.4476 62.8055 49.3829 58.8702C53.3182 54.9348 55.529 49.5974 55.529 44.0321V28.792H13.5605Z"
      fill="#FFC396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5762 28.7919C11.5762 27.6964 12.4643 26.8082 13.5599 26.8082H55.5284C56.6239 26.8082 57.5121 27.6964 57.5121 28.7919V44.032C57.5121 50.1235 55.0922 55.9655 50.7849 60.2728C46.4776 64.5802 40.6356 67 34.5441 67C28.4526 67 22.6107 64.5802 18.3033 60.2728C13.996 55.9655 11.5762 50.1235 11.5762 44.032V28.7919ZM15.5436 30.7757V44.032C15.5436 49.0713 17.5454 53.9041 21.1087 57.4674C24.672 61.0307 29.5049 63.0326 34.5441 63.0326C39.5834 63.0326 44.4162 61.0307 47.9795 57.4674C51.5428 53.9041 53.5446 49.0713 53.5446 44.032V30.7757H15.5436Z"
      fill="#303030"
    />
    <path
      d="M13.5605 28.792V44.0321C13.5605 49.5974 15.7714 54.9348 19.7067 58.8702C23.642 62.8055 28.9794 65.0163 34.5448 65.0163C40.1102 65.0163 45.4476 62.8055 49.3829 58.8702C53.3182 54.9348 55.529 49.5974 55.529 44.0321V28.792H13.5605Z"
      fill="#FFC396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5762 28.7919C11.5762 27.6964 12.4643 26.8082 13.5599 26.8082H55.5284C56.6239 26.8082 57.5121 27.6964 57.5121 28.7919V44.032C57.5121 50.1235 55.0922 55.9655 50.7849 60.2728C46.4776 64.5802 40.6356 67 34.5441 67C28.4526 67 22.6107 64.5802 18.3033 60.2728C13.996 55.9655 11.5762 50.1235 11.5762 44.032V28.7919ZM15.5436 30.7757V44.032C15.5436 49.0713 17.5454 53.9041 21.1087 57.4674C24.672 61.0307 29.5049 63.0326 34.5441 63.0326C39.5834 63.0326 44.4162 61.0307 47.9795 57.4674C51.5428 53.9041 53.5446 49.0713 53.5446 44.032V30.7757H15.5436Z"
      fill="#303030"
    />
    <path
      d="M15.5439 30.7757V44.032C15.5437 49.0317 17.5167 53.8295 21.0342 57.3825C24.5517 60.9355 29.3294 62.9567 34.3288 63.0067V30.7757H15.5439Z"
      fill="#FF9547"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6758 6.91943C25.8792 3.86497 30.1355 2.16098 34.5617 2.16098C38.9879 2.16098 43.2443 3.86497 46.4476 6.91943C49.651 9.97389 51.5555 14.1443 51.766 18.5655C51.7675 18.597 51.7683 18.6284 51.7683 18.6599V26.7759H47.8009V18.7088C47.628 15.3229 46.1643 12.1312 43.7098 9.79077C41.2443 7.43989 37.9684 6.12841 34.5617 6.12841C31.1551 6.12841 27.8792 7.43989 25.4137 9.79077C22.9482 12.1416 21.4824 15.3515 21.3204 18.7542L17.3574 18.5655C17.568 14.1443 19.4725 9.97389 22.6758 6.91943Z"
      fill="#303030"
    />
    <path
      d="M37.3738 53.1744C37.682 53.1758 37.9857 53.1008 38.2577 52.956C38.5297 52.8112 38.7616 52.6012 38.9326 52.3448C39.1035 52.0884 39.2081 51.7936 39.2372 51.4868C39.2662 51.18 39.2186 50.8708 39.0988 50.5869L36.9167 45.3258C37.8031 44.8052 38.4936 44.0074 38.8816 43.0555C39.2697 42.1036 39.3338 41.0505 39.064 40.0585C38.7943 39.0666 38.2056 38.191 37.3889 37.5667C36.5722 36.9424 35.5728 36.6042 34.5449 36.6042C33.5169 36.6042 32.5175 36.9424 31.7008 37.5667C30.8841 38.191 30.2955 39.0666 30.0257 40.0585C29.756 41.0505 29.82 42.1036 30.2081 43.0555C30.5962 44.0074 31.2867 44.8052 32.173 45.3258L29.991 50.5869C29.8734 50.8709 29.8276 51.1795 29.8576 51.4855C29.8876 51.7914 29.9925 52.0852 30.163 52.341C30.3335 52.5967 30.5644 52.8066 30.8352 52.9519C31.106 53.0973 31.4085 53.1737 31.7159 53.1744H37.3738Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.0767 34.4757C48.1722 34.4757 49.0604 35.3638 49.0604 36.4594V44.1591C49.052 46.771 48.2479 49.3184 46.7553 51.4618C46.1293 52.3608 44.8929 52.5821 43.9938 51.9561C43.0948 51.33 42.8735 50.0936 43.4995 49.1946C44.5308 47.7137 45.0865 45.9539 45.0929 44.1494V36.4594C45.0929 35.3638 45.9811 34.4757 47.0767 34.4757Z"
      fill="white"
    />
  </SvgIcon>
);

export default Lock;
