import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Users = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="56"
    viewBox="0 0 36 56"
    fill="none">
    <path
      d="M27.3284 3.5251V15.7395C27.3284 16.1261 27.1749 16.4969 26.9015 16.7702C26.6282 17.0435 26.2574 17.1971 25.8709 17.1971H15.3909C15.2728 17.196 15.1558 17.2202 15.0477 17.268C14.9396 17.3157 14.8429 17.386 14.7642 17.4741L13.0588 19.4126L13.1754 18.0571C13.1902 17.8637 13.1332 17.6717 13.0151 17.5178V2.09668H25.9146C26.2885 2.10783 26.6439 2.26235 26.9071 2.52824C27.1703 2.79414 27.3211 3.15105 27.3284 3.5251Z"
      fill="#FFC396"
    />
    <path
      d="M25.9146 0.466743H3.23481C2.42301 0.466743 1.64446 0.78923 1.07043 1.36326C0.496399 1.93729 0.173912 2.71584 0.173912 3.52764L0.173912 15.7421C0.177745 16.5527 0.501462 17.329 1.07466 17.9022C1.64786 18.4754 2.42419 18.7991 3.23481 18.803H11.4555L11.2369 21.7181C11.2246 21.8907 11.2655 22.0629 11.3541 22.2114C11.4427 22.36 11.5748 22.4778 11.7324 22.5489C11.8388 22.5641 11.9468 22.5641 12.0531 22.5489C12.1646 22.5555 12.2763 22.5395 12.3815 22.502C12.4867 22.4644 12.5832 22.406 12.6653 22.3303L15.7553 18.7738H25.9583C26.769 18.77 27.5453 18.4463 28.1185 17.8731C28.6917 17.2999 29.0154 16.5236 29.0192 15.7129V3.52764C29.0193 3.12197 28.9387 2.72034 28.7821 2.3461C28.6256 1.97185 28.3962 1.63246 28.1073 1.34765C27.8184 1.06284 27.4758 0.838297 27.0994 0.687069C26.723 0.535841 26.3202 0.460948 25.9146 0.466743V0.466743ZM27.3722 15.7421C27.3722 16.1287 27.2186 16.4994 26.9453 16.7727C26.6719 17.0461 26.3012 17.1997 25.9146 17.1997H15.3909C15.2728 17.1986 15.1558 17.2227 15.0477 17.2705C14.9396 17.3183 14.843 17.3885 14.7642 17.4766L13.0588 19.4152L13.1754 18.0596C13.1862 17.8303 13.1079 17.6057 12.9568 17.4329C12.7953 17.2681 12.5753 17.1738 12.3446 17.1705H3.23481C2.84824 17.1705 2.4775 17.0169 2.20415 16.7436C1.9308 16.4702 1.77724 16.0995 1.77724 15.7129V3.52764C1.77724 3.14107 1.9308 2.77033 2.20415 2.49698C2.4775 2.22364 2.84824 2.07007 3.23481 2.07007H25.9146C26.3012 2.07007 26.6719 2.22364 26.9453 2.49698C27.2186 2.77033 27.3722 3.14107 27.3722 3.52764V15.7421Z"
      fill="#272525"
    />
    <g clipPath="url(#clip0_38477_18706)">
      <path
        d="M26.5268 29.0215C25.6682 29.0159 24.8172 29.1819 24.0238 29.5098C23.2303 29.8377 22.5103 30.3208 21.9063 30.9309C21.5329 31.3002 21.2095 31.7167 20.9443 32.1699C20.9443 31.8638 20.9443 31.5723 20.9443 31.2662C20.9464 29.0647 20.1409 26.939 18.6805 25.2917C17.2201 23.6445 15.2062 22.59 13.0204 22.3283C10.8346 22.0665 8.62867 22.6156 6.82061 23.8715C5.01255 25.1273 3.72794 27.0028 3.21015 29.1425C2.69236 31.2821 2.97738 33.5374 4.01119 35.481C5.045 37.4246 6.7558 38.9215 8.81945 39.6881C10.8831 40.4546 13.1563 40.4377 15.2082 39.6403C17.2602 38.843 18.9485 37.3208 19.9531 35.362C19.9531 35.362 19.9531 35.4931 19.9531 35.566C19.957 37.2992 20.6482 38.9601 21.8751 40.1842C23.102 41.4084 24.7644 42.0959 26.4976 42.0959C28.231 42.0883 29.8911 41.3963 31.1168 40.1706C32.3425 38.9449 33.0344 37.2848 33.0421 35.5514C33.0383 33.8233 32.3511 32.1668 31.1305 30.9435C29.9098 29.7201 28.2549 29.0292 26.5268 29.0215V29.0215ZM17.0234 36.3094C16.3694 36.9651 15.5925 37.4854 14.7371 37.8404C13.8817 38.1954 12.9647 38.3782 12.0385 38.3782C11.1124 38.3782 10.1953 38.1954 9.33995 37.8404C8.48455 37.4854 7.70761 36.9651 7.05362 36.3094C6.0533 35.3252 5.36924 34.065 5.08891 32.6899C4.80857 31.3149 4.94469 29.8875 5.47987 28.5903C6.01505 27.293 6.92497 26.1848 8.09328 25.4074C9.2616 24.63 10.6352 24.2187 12.0385 24.2261C13.4298 24.2303 14.7888 24.6459 15.9445 25.4204C17.1002 26.195 18.0011 27.294 18.5339 28.5792C19.0667 29.8644 19.2076 31.2785 18.9388 32.6435C18.67 34.0086 18.0036 35.2637 17.0234 36.2511V36.3094ZM29.8063 38.8893C29.0485 39.648 28.0512 40.1205 26.9841 40.2263C25.917 40.3321 24.8463 40.0646 23.9543 39.4695C23.0623 38.8744 22.4043 37.9884 22.0923 36.9625C21.7802 35.9366 21.8336 34.8343 22.2432 33.8433C22.6528 32.8523 23.3933 32.034 24.3386 31.5278C25.2839 31.0215 26.3755 30.8587 27.4273 31.0671C28.4792 31.2754 29.4263 31.842 30.1072 32.6703C30.7882 33.4986 31.1609 34.5374 31.1618 35.6097C31.1492 36.8195 30.6625 37.9761 29.8063 38.831V38.8893Z"
        fill="#272525"
      />
      <path
        d="M31.4075 35.4555C31.4075 37.0001 30.881 38.4816 29.9438 39.5738C29.0067 40.6661 27.7355 41.2797 26.4102 41.2797V29.6191C27.7365 29.6223 29.0076 30.2386 29.9445 31.3328C30.8814 32.427 31.4075 33.9097 31.4075 35.4555Z"
        fill="#FFC396"
      />
      <path
        d="M27.2431 29.6191V41.2797C25.4485 41.2115 23.7545 40.5674 22.5135 39.4815C21.2724 38.3955 20.5799 36.9514 20.5799 35.4494C20.5799 33.9475 21.2724 32.5033 22.5135 31.4174C23.7545 30.3315 25.4485 29.6874 27.2431 29.6191V29.6191Z"
        fill="#FF9547"
      />
      <path
        d="M19.1223 31.0843C19.1223 33.0151 18.4243 34.8669 17.1818 36.2322C15.9393 37.5976 14.2541 38.3646 12.497 38.3646V38.3646V23.7889C14.2554 23.7929 15.9405 24.5633 17.1826 25.931C18.4247 27.2987 19.1223 29.152 19.1223 31.0843Z"
        fill="#FFC396"
      />
      <path
        d="M12.497 23.7889V38.3646C10.3558 38.2793 8.33454 37.4742 6.85374 36.1168C5.37295 34.7594 4.5466 32.9542 4.5466 31.0767C4.5466 29.1993 5.37295 27.3941 6.85374 26.0367C8.33454 24.6793 10.3558 23.8742 12.497 23.7889V23.7889Z"
        fill="#FF9547"
      />
    </g>
    <path
      d="M13.0151 2.09922V17.5057V17.4183C12.8536 17.2535 12.6336 17.1592 12.4029 17.1559H3.23478C2.84821 17.1559 2.47747 17.0024 2.20412 16.729C1.93077 16.4557 1.77721 16.0849 1.77721 15.6984V3.52764C1.77721 3.14107 1.93077 2.77033 2.20412 2.49698C2.47747 2.22363 2.84821 2.07007 3.23478 2.07007L13.0151 2.09922Z"
      fill="#FF9547"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.03352 11.1756C3.64118 11.2644 3.25216 11.0176 3.16461 10.6245C2.7713 8.85855 3.41619 7.20296 4.04926 6.13834C4.36918 5.60032 4.71035 5.17146 4.97153 4.91274C5.03771 4.84718 5.10781 4.78351 5.17863 4.72912C5.22796 4.69123 5.35262 4.59737 5.51738 4.55088C5.59354 4.5294 5.81605 4.47755 6.06068 4.59772C6.35932 4.74441 6.45459 5.02088 6.47349 5.18801C6.49006 5.33456 6.4607 5.45347 6.44557 5.50718C6.42721 5.57235 6.40357 5.62949 6.38323 5.67365C6.28235 5.89266 6.08712 6.03812 5.86843 6.08371C5.71217 6.26254 5.50581 6.53578 5.29916 6.8833C4.756 7.79671 4.30771 9.05627 4.58539 10.3031C4.67294 10.6962 4.42586 11.0868 4.03352 11.1756ZM6.06209 5.8868C6.07233 5.87893 6.07096 5.88118 6.05946 5.88887L6.06209 5.8868Z"
      fill="#FF9547"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.02798 12.0064C4.64206 12.2337 4.1497 12.1075 3.92828 11.7245C2.93357 10.0038 3.07322 8.0442 3.39402 6.70944C3.55614 6.03491 3.77649 5.46779 3.96799 5.10673C4.01652 5.01524 4.0698 4.9244 4.12689 4.84306C4.16666 4.78639 4.26777 4.64518 4.42682 4.54004C4.50034 4.49144 4.71872 4.36092 5.01687 4.40353C5.38083 4.45555 5.57221 4.71342 5.64696 4.88252C5.71251 5.0308 5.72042 5.16576 5.72201 5.22735C5.72394 5.30209 5.71766 5.37019 5.71062 5.42353C5.67568 5.68808 5.51683 5.90761 5.30043 6.03025C5.19375 6.27152 5.06499 6.62909 4.96027 7.06479C4.68503 8.20997 4.62356 9.68633 5.32584 10.9012C5.54726 11.2842 5.41391 11.779 5.02798 12.0064ZM5.44076 5.75721C5.44901 5.74545 5.44831 5.74827 5.43866 5.76028C5.43948 5.75905 5.44018 5.75803 5.44076 5.75721Z"
      fill="white"
    />
    <path
      d="M32.4591 43.5238C32.2867 43.3816 32.0703 43.3038 31.8469 43.3038C31.6235 43.3038 31.4071 43.3816 31.2347 43.5238C31.0744 43.6258 27.445 46.3223 24.0197 43.5238C23.8487 43.3789 23.6317 43.2994 23.4076 43.2994C23.1834 43.2994 22.9665 43.3789 22.7954 43.5238C22.4044 43.8787 22.0526 44.2745 21.7459 44.7044C21.2045 43.8585 20.558 43.0847 19.8219 42.4014C19.6496 42.2593 19.4332 42.1815 19.2098 42.1815C18.9864 42.1815 18.7699 42.2593 18.5976 42.4014C18.3789 42.5763 12.9276 46.6138 7.76783 42.4014C7.5955 42.2593 7.37906 42.1815 7.15565 42.1815C6.93224 42.1815 6.7158 42.2593 6.54347 42.4014C4.94227 44.0273 3.68275 45.9573 2.83914 48.0776C1.99553 50.1979 1.5849 52.4656 1.63146 54.7471C1.63274 54.9981 1.73213 55.2388 1.9084 55.4175C1.99424 55.5095 2.09804 55.5828 2.21337 55.6329C2.32871 55.6831 2.45312 55.709 2.57888 55.7091H23.495C23.6175 55.711 23.7392 55.6888 23.8531 55.6437C23.967 55.5985 24.071 55.5314 24.1589 55.4462C24.2469 55.3609 24.3172 55.2591 24.3659 55.1467C24.4145 55.0342 24.4405 54.9133 24.4424 54.7908C24.4772 54.3056 24.4772 53.8185 24.4424 53.3332H34.8641C35.1131 53.3297 35.3515 53.2312 35.5303 53.0578C35.7091 52.8844 35.8149 52.6492 35.8261 52.4004C35.7678 52.1817 35.9135 46.6867 32.4591 43.5238ZM3.54088 53.7996C3.71272 50.325 5.01824 47.0023 7.25768 44.34C12.1988 47.7945 17.242 45.404 19.0494 44.34C21.8625 47.3572 22.4018 51.9048 22.5039 53.8288L3.54088 53.7996ZM24.1655 51.4821C23.9025 49.8008 23.4122 48.163 22.7079 46.6138C22.9349 46.2369 23.1882 45.8765 23.4659 45.5352C24.6919 46.2941 26.1052 46.6961 27.5471 46.6961C28.9889 46.6961 30.4023 46.2941 31.6283 45.5352C32.9444 47.2504 33.7116 49.3233 33.8292 51.4821H24.1655Z"
      fill="#272525"
    />
    <path
      d="M27.3284 46.6419V51.4811H24.1655C23.9025 49.7997 23.4122 48.162 22.7079 46.6128C22.9349 46.2359 23.1882 45.8754 23.4659 45.5342C24.6212 46.2647 25.9615 46.6491 27.3284 46.6419V46.6419Z"
      fill="#FF9547"
    />
    <path
      d="M22.5038 53.8002H13.6564V46.1043C15.5916 45.9333 17.4617 45.3199 19.1223 44.3115C21.8625 47.3286 22.4018 51.8763 22.5038 53.8002Z"
      fill="#FFC396"
    />
    <path
      d="M13.2046 45.9584V53.6544H3.08904C3.26089 50.1797 4.56641 46.857 6.80585 44.1948C8.65975 45.5278 10.9295 46.1534 13.2046 45.9584V45.9584Z"
      fill="#FF9547"
    />
    <path
      d="M33.8292 51.4802H27.3284V46.6411C28.8654 46.6461 30.3755 46.2383 31.7011 45.4604C33.005 47.2045 33.7473 49.3041 33.8292 51.4802V51.4802Z"
      fill="#FFC396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77271 52.9146C5.38037 53.0034 4.99135 52.7567 4.9038 52.3636C4.51049 50.5976 5.15538 48.942 5.78845 47.8774C6.10837 47.3393 6.44953 46.9105 6.71072 46.6518C6.7769 46.5862 6.847 46.5225 6.91782 46.4681C6.96715 46.4303 7.09181 46.3364 7.25657 46.2899C7.33273 46.2684 7.55524 46.2166 7.79987 46.3367C8.09851 46.4834 8.19378 46.7599 8.21268 46.927C8.22925 47.0736 8.19989 47.1925 8.18476 47.2462C8.16639 47.3114 8.14276 47.3685 8.12242 47.4127C8.02154 47.6317 7.82631 47.7771 7.60761 47.8227C7.45136 48.0016 7.24499 48.2748 7.03834 48.6223C6.49519 49.5357 6.0469 50.7953 6.32457 52.0421C6.41212 52.4352 6.16505 52.8258 5.77271 52.9146ZM7.80127 47.6258C7.81151 47.618 7.81015 47.6202 7.79865 47.6279C7.79968 47.6271 7.80056 47.6264 7.80127 47.6258Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_38477_18706">
        <rect
          width="32.0666"
          height="21.8636"
          fill="white"
          transform="translate(1.63146 20.8737)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Users;
